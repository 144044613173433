import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SubscribeForm from '../components/SubscribeForm';
import { Heading } from 'bumbag/Heading';
import { Container } from 'bumbag/Container';
import { Button } from 'bumbag/Button';
import { Text } from 'bumbag/Text';
import { Stack } from 'bumbag/Stack';
import { Link } from 'bumbag/Link';
import { Paragraph } from 'bumbag/Paragraph';
import { Flex } from 'bumbag/Flex';
import { headerHeight } from 'shared-bumbag/src/theme';

const IndexPage = () => {
  return (
    <Layout>
      <SEO/>
      <Flex alignX="center" alignY="center" height={`calc(100vh - ${headerHeight}px)`}>
        <Container breakpoint="tablet" padding="major-2">
          <Stack spacing="major-4" marginBottom="major-5">
            <Heading lineHeight="200">I'm building a <Text use="em" color="highlight">new way</Text> to stay healthy at your desk</Heading>
            <Paragraph lineHeight="600">Sitting is the new smoking. Standing desks and gym sessions are not sufficient to combat a desk-based lifestyle. The only solution is to move more frequently.</Paragraph>
            <Paragraph lineHeight="600">Learn more about the <Link href="http://ankle.io/sitting">dangers of sitting</Link> in my latest blog post.</Paragraph>
          </Stack>
          <SubscribeForm/>
        </Container>
      </Flex>
    </Layout>
  );
};

export default IndexPage;
